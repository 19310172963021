@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-size: 18px;
  line-height: 1.6;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
p { font-size: 1.125rem; }